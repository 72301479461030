@font-face {
    font-family: gloableFont;
    src: url(../public/font/Excon-Black.ttf);
    src: url(../public/font/Excon-Bold.ttf);
    src: url(../public/font/Excon-Light.ttf);
    src: url(../public/font/Excon-Medium.ttf);
    src: url(../public/font/Excon-Regular.ttf);
    src: url(../public/font/Excon-Thin.ttf);
    src: url(../public/font/Excon-Variable.ttf);
}

@font-face {
    font-family: myFont;
    src: url(../public/Chaumont_scriptBeta-Regular.otf);
}

@font-face {
    font-family: Moore;
    src: url(../public/Moore-Henry.otf);
}

* {
    outline: none;
    transition: 0.3s all ease-in-out;
}

.container {
    margin: auto;
    padding: 0 50px;
}

body {
    font-family: gloableFont;
}

html {
    scroll-behavior: smooth;
}

.active {
    color: #43B8EA;
    font-weight: bold;
    border-radius: 30px;
}

.form-dropdown .p-dropdown {
    background-color: #FFF5EF;
    color: black;
    box-shadow: none !important;
    margin-top: 10px;
    border: none !important;
}

.form-dropdown .p-dropdown .p-dropdown-label.p-placeholder {
    color: black !important;
}

@media (max-width: 1023px) {
    .container {
        padding: 0 10px !important;
    }
}

@media only screen and (max-width: 640px) {
    [data-aos] {
        opacity: 1 !important;
        transform: translate(0) scale(1) !important;
    }
}